.custom-label {
  font-size: 1.2em;
  color: white;
}

.custom-form-control::placeholder {
  /* Chrome, Firefox, Opera, Safari 10.1+ */
  color: rgb(19, 18, 18);
  opacity: 1; /* Firefox */
}

.custom-form-control:-ms-input-placeholder {
  /* Internet Explorer 10-11 */
  color: rgb(19, 18, 18);
}

.custom-form-control::-ms-input-placeholder {
  /* Microsoft Edge */
  color: rgb(19, 18, 18);
}

.react-bootstrap .custom-form-control {
  border-radius: 12px !important;
}
