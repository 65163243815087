
#content {
  width: 100%;
  max-width: 600px;
  text-align: center;
  background-color: #fff;
}
@media (min-width: 600px) {
  body {
    background-color: #e7e7e7;
    padding: 0;
  }

  #content {
    width: 40%;
    margin: 20px auto;
    padding: 20px 40px 30px 40px;
    -webkit-box-shadow: 0 0 20px 0 #999;
    -moz-box-shadow: 0 0 20px 0 #999;
    box-shadow: 0 0 20px 0 #999;
  }
}

.frame {
  border: 1px solid #ccc;
  padding: 5px;
  margin-bottom: 20px;
}

.frame > img { width: 100% }

#controls { margin-bottom: 40px; }

#controls button {
  margin: 0 5px;
  min-width: 40px;
  color: #555;
  font-size: 16px;
  font-weight: bold;
  line-height: 30px;
}

#controls button:hover { color: #333; }

#data {
  list-style: none;
  display: inline-block;
  padding: 0;
  margin: 0;
}

#data .column {
  display: inline-block;
  margin: 0 15px;
}

#data li {
  margin-bottom: 10px;
  font-weight: bold;
  text-align: left;
}

#data span {
  display: inline-block;
  font-weight: normal;
}

.preview
{
  width:200px;
  border:solid 1px #dedede;
  padding:10px;
}
#preview
{
  color:#cc0000;
  font-size:12px
}