.neumorphic-form-container {
  background: #1b1b19; /* cor de fundo */
  border-radius: 10px; /* bordas arredondadas */
  box-shadow: 8px 8px 15px #111,
    /* sombra externa mais escura */ -8px -8px 15px #242422; /* sombra interna mais clara */
  padding: 20px; /* espaçamento interno */
}

.neumorphic-form-container input {
  background: white; /* fundo branco para os inputs */
  border-radius: 5px; /* bordas arredondadas para os inputs */
  border: none; /* remover a borda padrão */
  box-shadow: inset 2px 2px 5px #0e0e0e,
    /* sombra interna para efeito de profundidade */ inset -2px -2px 5px #262626; /* sombra interna mais clara */
  padding: 10px; /* espaço interno no input */
  margin-bottom: 10px; /* espaço abaixo do input */
}

.neumorphic-form-container input:focus {
  outline: none; /* remove o contorno ao focar */
  box-shadow: inset 1px 1px 3px #0e0e0e,
    /* ajusta a sombra interna ao focar */ inset -1px -1px 3px #262626;
}

.neumorphic-form-container button {
  border-radius: 5px; /* bordas arredondadas para o botão */
}

.img-container-icon {
  width:100%;
  padding-left: 25%;
  overflow:hidden;
 }
 .img-container-icon > img {
 width:50%;
 object-fit:cover;
 }