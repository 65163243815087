html {
  font-size: 14px;
}
.remove{overflow-x: hidden;}

:root{
  --barra-menu: #d8030b; /**/
  --barra-menu-hover: #AA4343;/**/
  --barra-menu-font: #d8030b; /**/
  --barra-menu-hover-font: #AA4343;/**/
  --barra-dropdown: #d8030b;/**/
  --barra-dropdown-hover: #AA4343;/**/
  --barra-dropdown-font: #d8030b;/**/
  --barra-dropdown-hover-font: #AA4343;/**/
  --nome: #dd0000;  /**/
  --cor-empresas: #dd0000; /**/
  --minha-conta-background: #d8030b; /**/
  --minha-conta-hover: #AA4343; /**/
  --cor-letra-menu: white;
  --cor-icone-menu: white;
  --cor-letra-dropdown: white;
  --cor-icone-dropdown: white;
  --cor-letra-icone-minha-conta: white;
  --cor-letra-grafico: #d8030b;/**/
  --cor-icone-grafico: #d8030b;/**/
  --cor-caminho: #dd0000; /**/

}



input:focus{
	border: var(--barra-menu) 2px solid !important;
}

.page-header .page-header-menu {
	background: var(--barra-menu) !important;
}

.page-header .page-header-menu .hor-menu .navbar-nav > li > a {
	color: var(--cor-letra-menu) !important;
}

.page-header .page-header-menu .hor-menu .navbar-nav > li > a > i {
	color: var(--cor-icone-menu) !important;
}

.page-sidebar .page-sidebar-menu > li > a > i[class*="icon-"],
.page-sidebar .page-sidebar-menu > li > a > i[class*="fa-"],
.page-sidebar .page-sidebar-menu > li > a > i[class^="icon-"],
.page-sidebar .page-sidebar-menu > li > a > i[class^="fa-"],
.page-sidebar-closed.page-sidebar-fixed .page-sidebar:hover .page-sidebar-menu > li > a > i[class*="icon-"],
.page-sidebar-closed.page-sidebar-fixed .page-sidebar:hover .page-sidebar-menu > li > a > i[class*="fa-"],
.page-sidebar-closed.page-sidebar-fixed .page-sidebar:hover .page-sidebar-menu > li > a > i[class^="icon-"],
.page-sidebar-closed.page-sidebar-fixed .page-sidebar:hover .page-sidebar-menu > li > a > i[class^="fa-"] {
    color: #01b070 !important;
}

.page-header .page-header-menu .hor-menu .navbar-nav > li:hover > a,
.page-header .page-header-menu .hor-menu .navbar-nav > li.open > a,
.page-header .page-header-menu .hor-menu .navbar-nav > li > a:hover,
.page-header .page-header-menu .hor-menu .navbar-nav > li > a:focus,
.page-header .page-header-menu .hor-menu .navbar-nav > li > a:active {
	color: var(--barra-menu-hover-font) !important;
	background: var(--barra-menu-hover) !important;
}

.page-header .page-header-menu .hor-menu .navbar-nav > li.active > a,
.page-header .page-header-menu .hor-menu .navbar-nav > li.active > a:hover,
.page-header .page-header-menu .hor-menu .navbar-nav > li.current > a,
.page-header .page-header-menu .hor-menu .navbar-nav > li.current > a:hover {
	color: var(--barra-menu-font)  !important;
	background: var(--barra-menu)  !important;
}

.page-header .page-header-menu .hor-menu .navbar-nav > li.active > a > i,
.page-header .page-header-menu .hor-menu .navbar-nav > li.active > a:hover > i,
.page-header .page-header-menu .hor-menu .navbar-nav > li.current > a > i,
.page-header .page-header-menu .hor-menu .navbar-nav > li.current > a:hover > i {
	color: var(--barra-dropdown-font) !important;
}

.page-header .page-header-menu .hor-menu .navbar-nav > li .dropdown-menu {
	background: var(--barra-dropdown) !important;
}

.page-header .page-header-menu .hor-menu .navbar-nav > li .dropdown-menu li > a {
	color: var(--cor-letra-dropdown) !important;
} /* cor da letra dropdown */

.page-header .page-header-menu .hor-menu .navbar-nav > li .dropdown-menu li > a > i {
	color: var(--cor-icone-dropdown) !important;
} /* cor do icone dropdown */

.page-header .page-header-menu .hor-menu .navbar-nav > li .dropdown-menu li:hover > a {
	color: var(--barra-dropdown-hover-font) !important;
	background: var(--barra-dropdown-hover) !important;
}

.page-header .page-header-menu .hor-menu .navbar-nav > li .dropdown-menu li.active > a,
.page-header .page-header-menu .hor-menu .navbar-nav > li .dropdown-menu li.active > a:hover,
.page-header .page-header-menu .hor-menu .navbar-nav > li .dropdown-menu li.current > a,
.page-header .page-header-menu .hor-menu .navbar-nav > li .dropdown-menu li.current > a:hover {
	color: white !important;
	background: var(--barra-menu-hover) !important;
}

.page-wrapper-middle{
	background-color: var(--background--geral)!important;
}

.md-checkbox-inline .md-checkbox {
  margin-right: 16px !important;
}

.react-datepicker {
  font-size: 0.8em;
}
.react-datepicker__header {
  padding-top: 0.8em;
}
.react-datepicker__month {
  margin: 0.4em 1em;
}
.react-datepicker__day-name, .react-datepicker__day {
  width: 1.9em;
  line-height: 1.9em;
  margin: 0.166em;
}
.react-datepicker__current-month {
  font-size: 1.1em;
}
.react-datepicker__navigation {
  top: 1em;
  line-height: 1.7em;
  border: 0.45em solid transparent;
}
.react-datepicker__navigation--previous {
  border-right-color: #ccc;
  left: 1em;
}
.react-datepicker__navigation--next {
  border-left-color: #ccc;
  right: 1em;
}

.react-datepicker-popper {
	z-index: 2;
}

.dropup .dropdown-menu, .navbar-fixed-bottom .dropdown .dropdown-menu {
  bottom: -340% !important;
}

.header-table-left {
  float: left;
}

.tabbable-line > .nav-tabs > li.active {
  border-bottom: 4px solid var(--minha-conta-background) !important;
}

.tabbable-line > .nav-tabs > li:hover {
  border-bottom: 4px solid var(--minha-conta-hover) !important;
}

.note.note-success {
  background-color: #eef7ea !important;
  border-color: #c9e2b3 !important;
  color: #3c763d !important;
}

.floating-button {
  position: absolute;
  bottom: 20px;
  right: 47%;
  cursor: pointer;
}

.logo-default {
	margin: 12.5px 0px 0px 0px !important;
	height: 50;
}

.floating-input {
  z-index: 0; 
  position: absolute; 
  left: 0px; 
  top: 0px;
}

.my-form-control {
	display: block;
	width: 165%;
	height: 34px;
	padding: 6px 12px 6px 30px;
	font-size: 14px;
	line-height: 1.42857;
	margin-top: 8px;
	color: #555555;
	background-color: #fff;
	background-image: none;
	border: 1px solid #c2cad8;
	border-radius: 4px;
	-webkit-box-shadow: inset 0 1px 1px rgba(0, 0, 0, 0.075);
	box-shadow: inset 0 1px 1px rgba(0, 0, 0, 0.075);
	-webkit-transition: border-color ease-in-out 0.15s, box-shadow ease-in-out 0.15s;
	-o-transition: border-color ease-in-out 0.15s, box-shadow ease-in-out 0.15s;
	-webkit-transition: border-color ease-in-out 0.15s, -webkit-box-shadow ease-in-out 0.15s;
	transition: border-color ease-in-out 0.15s, -webkit-box-shadow ease-in-out 0.15s;
	transition: border-color ease-in-out 0.15s, box-shadow ease-in-out 0.15s;
	transition: border-color ease-in-out 0.15s, box-shadow ease-in-out 0.15s, -webkit-box-shadow ease-in-out 0.15s;
}

.daterangepicker .ranges ul {
	list-style: none;
	margin: 0;
	padding: 0;
}

.daterangepicker .ranges li {
	font-size: 13px;
	background: #f2f2f2;
	border: 1px solid #f2f2f2;
	color: #08c;
	padding: 3px 12px;
	margin-bottom: 8px;
	-webkit-border-radius: 5px;
	-moz-border-radius: 5px;
	border-radius: 5px;
	cursor: pointer;
}

.daterangepicker .ranges .range_inputs > div {
	float: left;
}

.daterangepicker.opensleft .ranges, .daterangepicker.opensleft .calendar {
	float: left;
	margin: 4px;
}

.daterangepicker_start_input{
  position: absolute;
  top: 0;
  left: 189px ;
}

.daterangepicker_end_input{
  position: absolute;
  top: 0;
  left: 388px;
}

.daterangepicker.opensleft::before {
	position: absolute;
	top: -7px;
	right: 9px;
	display: inline-block;
	border-right: 7px solid transparent;
	border-bottom: 7px solid #ccc;
	border-left: 7px solid transparent;
	border-bottom-color: rgba(0, 0, 0, 0.2);
	content: '';
}

.daterangepicker.opensleft::after {
	position: absolute;
	top: -6px;
	right: 10px;
	display: inline-block;
	border-right: 6px solid transparent;
	border-bottom: 6px solid #fff;
	border-left: 6px solid transparent;
	content: '';
}

.login .logo {
  margin: 20px auto 20px auto;
  padding: 15px;
  text-align: center;
}

.login .copyright {
  text-align: center;
  margin: 0 auto;
  padding: 10px;
  color: #eee;
  font-size: 13px;
}

.login .content {
	background: url('./images/bg-white-lock.png') repeat;
	width: 360px;
	margin: 0 auto;
	margin-bottom: 0px;
	padding: 30px;
	padding-top: 20px;
	padding-bottom: 15px;
	-webkit-border-radius: 7px !important;
	-moz-border-radius: 7px !important;
	-ms-border-radius: 7px !important;
	-o-border-radius: 7px !important;
	border-radius: 7px !important;
}

.caridade {
	-webkit-border-radius: 7px !important;
	-moz-border-radius: 7px !important;
	-ms-border-radius: 7px !important;
	-o-border-radius: 7px !important;
	border-radius: 7px !important;
}

.login .content .login-form, .login .content .forget-form {
	padding: 0px;
	margin: 0px;
}

.login .content h3 {
	color: #eee;
}

.login .content .form-title {
	font-weight: 300;
	margin-bottom: 25px;
}

.login .content .form-actions .checkbox {
	margin-top: 8px;
	display: inline-block;
}

.login .content .form-actions .checkbox {
  margin-left: 25px;
  margin-bottom: 15px;
	padding-left: 0;
}

.login .content p, .login .content label {
	color: #fff;
}

.login .content .form-actions {
	background-color: transparent;
	clear: both;
	border: 0px;
	padding: 0px 30px 25px 30px;
	margin-left: -30px;
	margin-right: -30px;
}

.backstretch {
	left: 0px;
	top: 0px;
	overflow: hidden;
	margin: 0px;
	padding: 0px;
	height: 968px;
	width: 100%;
	z-index: -999999;
	position: fixed;
}

.backstretch img {
	position: absolute;
	margin: 0px;
	padding: 0px;
	border: medium none;
	width: 100%;
	height: 968px;
	max-height: none;
	max-width: none;
	z-index: -999999;
	left: 0px;
	top: -160.797px;
}

.react-autosuggest__suggestions-container--open {
	border-radius: 3px;
	box-shadow: rgba(0, 0, 0, 0.1) 0px 2px 12px;
	background: rgba(255, 255, 255, 1) none repeat scroll 0% 0%;
	padding: 2px 0px;
	font-size: 100%;
	position: absolute;
	top: 35px;
	left: 27px;
	width: 96%;
}

.react-autosuggest__suggestion--highlighted {
	background: lightgray none repeat scroll 0% 0%;
}

.react-autosuggest__suggestion {
	border-bottom: 1px solid lightgrey;
}

.react-autosuggest__suggestions-list {
	padding: 0;
	list-style-type: none;
}

.autoComp div {
	z-index: 10;
}

.func-autocomp .react-autosuggest__suggestions-container--open {
	border-radius: 3px;
	box-shadow: rgba(0, 0, 0, 0.1) 0px 2px 12px;
	background: rgba(255, 255, 255, 1) none repeat scroll 0% 0%;
	padding: 2px 0px;
	font-size: 100%;
	position: absolute;
	top: 34px;
	left: 15px;
	width: 92%
}

.favoritos-dialog-modal{
	width: 98% !important;
}

.select2-dropdown{
	z-index: 9999999999999999 !important;
}

.breadcrumb .fa {
	font-size: 14px !important;
	color: #FFFFFF !important;
} /* icone do caminho */
.breadcrumb a {
	color: #FFFFFF !important;
} /* icone do caminho */

a {
	color: var(--cor-empresas) !important;
} /* cores do menu em cima ( empresa ) */

a:hover, a:active {
	color: var(--barra-menu-hover) !important;
}

.page-head .page-title > h1 {
	color: #FFFFFF !important;
}

.portlet.light > .portlet-title > .caption {
	color: var(--cor-letra-grafico) !important;
} /* nome do grafico */

.portlet.light > .portlet-title > .caption > i {
	color: var(--cor-icone-grafico) !important;
} /* cor do icone */
.page-header {
	background-color: var(--background--geral)!important;
}
.page-header .page-header-top .top-menu .navbar-nav > li.dropdown-user > .dropdown-toggle > .username {
	color: var(--nome) !important; /* email/nome pessoa */
}

.page-header .page-header-top .top-menu .navbar-nav > li.dropdown-dark .dropdown-menu {
	background: var(--background--geral)!important; /*menu minha conta background #d8030b */
	background-color: var(--background--geral)!important;
}

.page-header .page-header-top .top-menu .navbar-nav > li.dropdown-dark .dropdown-menu::after {
	border-bottom-color: var(--minha-conta-background) !important;
}

.page-header .page-header-top .top-menu .navbar-nav > li.dropdown-dark .dropdown-menu.dropdown-menu-default > li a:hover,
.page-header .page-header-top .top-menu .navbar-nav > li.dropdown-dark .dropdown-menu .dropdown-menu-list > li a:hover {
	background: var(--background--geral)!important; /* hover menu minha conta #AA4343 */
}

.page-header .page-header-top .top-menu .navbar-nav > li.dropdown-dark .dropdown-menu.dropdown-menu-default > li.divider, 
.page-header .page-header-top .top-menu .navbar-nav > li.dropdown-dark .dropdown-menu.dropdown-menu-default > li a > i,
.page-header .page-header-top .top-menu .navbar-nav > li.dropdown-dark .dropdown-menu .dropdown-menu-list > li a > i,
.page-header .page-header-top .top-menu .navbar-nav > li.dropdown-dark .dropdown-menu.dropdown-menu-default > li a,
.page-header .page-header-top .top-menu .navbar-nav > li.dropdown-dark .dropdown-menu .dropdown-menu-list > li a {
	color: var(--cor-letra-dropdown) !important; /* email/nome pessoa */
} /* cor da letra menu minha conta */

.page-header .page-header-top .top-menu {
	margin: 0 !important;
}

.page-header .page-header-menu .hor-menu .navbar-nav > li.classic-menu-dropdown .dropdown-menu {
	min-width: 214px !important;
}

.pagamentoOKx {
	color: #ffffff;
	font-weight: bold;
	padding: 10;
	background: var(--barra-menu)  !important;
}
.pagamentoOKx:hover {
	color: #ffffff;
	font-weight: bold;
	padding: 10;
	background: var(--barra-menu-hover) !important;
}
.pagamentoNOKx {
	color: #000000;
	padding: 10;
	background: #CCCCCC  !important;
}
.pagamentoNOKx:hover {
	color: #000000;
	padding: 10;
	background: #AAAAAA !important;
}
.pagamentoOK {
	color: #ffffff;
	font-weight: bold;
	padding: 10;
	width: 90%;
	background: var(--barra-menu)  !important;
}
.pagamentoOK:hover {
	color: #ffffff;
	font-weight: bold;
	padding: 10;
	width: 90%;
	background: var(--barra-menu-hover) !important;
}
.pagamentoNOK {
	color: #000000;
	padding: 10;
	width: 90%;
	background: #CCCCCC  !important;
}
.pagamentoNOK:hover {
	color: #000000;
	padding: 10;
	width: 90%;
	background: #AAAAAA !important;
}
.pagamentoSvgOK {
	color: #ffffff;
	fill:  #ffffff;
	height: 50;
	width: 50;
}
.pagamentoSvgNOK {
	color: #000000;
	fill:  #000000;
	height: 50;
	width: 50;
}
.link-button2 {
	background-color: transparent;
	border: none;
	cursor: pointer;
	display: inline;
	margin: 0;
	padding: 0;
  }
  
  .link-button2:hover,
  .link-button2:focus {
  text-decoration: none;
  }
.link-button {
	background-color: transparent;
	border: none;
	cursor: pointer;
	text-decoration: underline;
	display: inline;
	margin: 0;
	padding: 0;
  }
  
  .link-button:hover,
  .link-button:focus {
  text-decoration: none;
  }
  .linhaDestinoON{
	  background-color: #d4d3d3;
	  border: none
  }
  .linhaDestinoOff{
	background-color: #ffffff;
	border: #d4d3d3 1px solid
}
.btn-warning-go {
	color: #fff;
	background-color: var(--barra-menu)  !important;;
	border-color: var(--barra-menu)  !important;; }
	.btn-warning-go:focus, .btn-warning-go.focus {
	  color: #fff;
	  background-color: var(--barra-menu)  !important;;
	  border-color: var(--barra-menu)  !important;; }
	.btn-warning-go:hover {
	  color: #fff;
	  background-color: var(--barra-menu)  !important;;
	  border-color: var(--barra-menu)  !important;; }
	.btn-warning-go:active, .btn-warning-go.active,
	.open > .btn-warning-go.dropdown-toggle {
	  color: #fff;
	  background-color: var(--barra-menu)  !important;;
	  border-color: var(--barra-menu)  !important;; }
	  .btn-warning-go:active:hover, .btn-warning-go:active:focus, .btn-warning-go:active.focus, .btn-warning-go.active:hover, .btn-warning-go.active:focus, .btn-warning-go.active.focus,
	  .open > .btn-warning-go.dropdown-toggle:hover,
	  .open > .btn-warning-go.dropdown-toggle:focus,
	  .open > .btn-warning-go.dropdown-toggle.focus {
		color: #fff;
		background-color: var(--barra-menu)  !important;;
		border-color: var(--barra-menu)  !important;; }
	.btn-warning-go:active, .btn-warning-go.active,
	.open > .btn-warning-go.dropdown-toggle {
	  background-image: none; }
	.btn-warning-go.disabled:hover, .btn-warning-go.disabled:focus, .btn-warning-go.disabled.focus, .btn-warning[disabled]:hover, .btn-warning[disabled]:focus, .btn-warning[disabled].focus,
	fieldset[disabled] .btn-warning-go:hover,
	fieldset[disabled] .btn-warning-go:focus,
	fieldset[disabled] .btn-warning-go.focus {
	  background-color: var(--barra-menu)  !important;;
	  border-color: var(--barra-menu)  !important;; }
	.btn-warning .badge {
	  color: var(--barra-menu)  !important;;
	  background-color: #fff; }


