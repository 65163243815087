body.guillotine-dragging, body.guillotine-dragging * {
  cursor: move !important;
  cursor: -webkit-grabbing !important;
  cursor:    -moz-grabbing !important;
  cursor:         grabbing !important;
  cursor: grabbing, move;  /* IE hack */
}

.guillotine-window {
  display: block;
  position: relative;
  overflow: hidden;
  cursor: move;
  cursor: -webkit-grab;
  cursor:    -moz-grab;
  cursor:         grab;
  cursor: grab, move;  /* IE hack */
}

.guillotine-canvas {
  position: absolute;
  top: 0;
  left: 0;
  text-align: center;
  margin: 0 !important;
  padding: 0 !important;
  border: none !important;
}

.guillotine-canvas > * {
  position: absolute;
  top: 0;
  left: 0;
  max-width: none;
  max-height: none;
  width: 100%;
  height: 100%;
  margin: 0 !important;
  padding: 0 !important;
  border: none !important;
}

.guillotine-sample {
  position: absolute !important;
  top: -100000px     !important;
  left: -100000px    !important;
  width: auto        !important;
  height: auto       !important;
}
