html {
  font-size: 14px;
}
.remove {
  overflow-x: hidden;
}

body {
  margin: 0;
  padding: 0;
  font-family: Popins, sans-serif;
  background-color: var(--background--geral) !important;
}

:root {
  --barra-menu: #d8030b; /**/
  --barra-menu-hover: #aa4343; /**/
  --barra-menu-font: #d8030b; /**/
  --barra-menu-hover-font: #aa4343; /**/
  --barra-dropdown: #d8030b; /**/
  --barra-dropdown-hover: #aa4343; /**/
  --barra-dropdown-font: #d8030b; /**/
  --barra-dropdown-hover-font: #aa4343; /**/
  --nome: #dd0000; /**/
  --cor-empresas: #dd0000; /**/
  --minha-conta-background: #d8030b; /**/
  --minha-conta-hover: #aa4343; /**/
  --cor-letra-menu: white;
  --cor-icone-menu: white;
  --cor-letra-dropdown: white;
  --cor-icone-dropdown: white;
  --cor-letra-icone-minha-conta: white;
  --cor-letra-grafico: #d8030b; /**/
  --cor-icone-grafico: #d8030b; /**/
  --cor-caminho: #dd0000; /**/
  --cor-01: #dd0000; /**/
  --cor-02: #dd0000; /**/
  --cor-03: #dd0000; /**/
  --cor-04: #dd0000; /**/
  --cor-05: #dd0000; /**/
  --cor-06: #dd0000; /**/
  --nav-heade-size1: 90px;
  --nav-heade-size2: 25px;
  --round-size5: 5px;
  --round-size7: 7px;
  --round-size12: 12px;
  --polygon: 100% 0, 100% 50%, 0 50%, 0 0;
  --svg: url('data:image/svg+xml,<svg xmlns="http://www.w3.org/2000/svg"><g stroke-linecap="round" stroke="none" fill="rgb(0, 0, 0)"><rect id="svg_3" height="30%" width="100%" x="0"/></g></svg>');
}

input:focus {
  border: var(--barra-menu) 2px solid !important;
}

.page-header .page-header-menu {
  background: var(--barra-menu) !important;
}

.navbar {
  border-radius: 0px !important
}

.page-header .page-header-menu .hor-menu .navbar-nav > li > a {
  color: var(--cor-letra-menu) !important;
}

.page-header .page-header-menu .hor-menu .navbar-nav > li > a > i {
  color: var(--cor-icone-menu) !important;
}

.page-sidebar .page-sidebar-menu > li > a > i[class*="icon-"],
.page-sidebar .page-sidebar-menu > li > a > i[class*="fa-"],
.page-sidebar .page-sidebar-menu > li > a > i[class^="icon-"],
.page-sidebar .page-sidebar-menu > li > a > i[class^="fa-"],
.page-sidebar-closed.page-sidebar-fixed
  .page-sidebar:hover
  .page-sidebar-menu
  > li
  > a
  > i[class*="icon-"],
.page-sidebar-closed.page-sidebar-fixed
  .page-sidebar:hover
  .page-sidebar-menu
  > li
  > a
  > i[class*="fa-"],
.page-sidebar-closed.page-sidebar-fixed
  .page-sidebar:hover
  .page-sidebar-menu
  > li
  > a
  > i[class^="icon-"],
.page-sidebar-closed.page-sidebar-fixed
  .page-sidebar:hover
  .page-sidebar-menu
  > li
  > a
  > i[class^="fa-"] {
  color: #01b070 !important;
}

.page-header .page-header-menu .hor-menu .navbar-nav > li:hover > a,
.page-header .page-header-menu .hor-menu .navbar-nav > li.open > a,
.page-header .page-header-menu .hor-menu .navbar-nav > li > a:hover,
.page-header .page-header-menu .hor-menu .navbar-nav > li > a:focus,
.page-header .page-header-menu .hor-menu .navbar-nav > li > a:active {
  color: var(--barra-menu-hover-font) !important;
  background: var(--barra-menu-hover) !important;
}

.page-header .page-header-menu .hor-menu .navbar-nav > li.active > a,
.page-header .page-header-menu .hor-menu .navbar-nav > li.active > a:hover,
.page-header .page-header-menu .hor-menu .navbar-nav > li.current > a,
.page-header .page-header-menu .hor-menu .navbar-nav > li.current > a:hover {
  color: var(--barra-menu-font) !important;
  background: var(--barra-menu) !important;
}

.page-header .page-header-menu .hor-menu .navbar-nav > li.active > a > i,
.page-header .page-header-menu .hor-menu .navbar-nav > li.active > a:hover > i,
.page-header .page-header-menu .hor-menu .navbar-nav > li.current > a > i,
.page-header
  .page-header-menu
  .hor-menu
  .navbar-nav
  > li.current
  > a:hover
  > i {
  color: var(--barra-dropdown-font) !important;
}

.page-header .page-header-menu .hor-menu .navbar-nav > li .dropdown-menu {
  background: var(--barra-dropdown) !important;
}

.page-header
  .page-header-menu
  .hor-menu
  .navbar-nav
  > li
  .dropdown-menu
  li
  > a {
  color: var(--cor-letra-dropdown) !important;
} /* cor da letra dropdown */

.page-header
  .page-header-menu
  .hor-menu
  .navbar-nav
  > li
  .dropdown-menu
  li
  > a
  > i {
  color: var(--cor-icone-dropdown) !important;
} /* cor do icone dropdown */

.page-header
  .page-header-menu
  .hor-menu
  .navbar-nav
  > li
  .dropdown-menu
  li:hover
  > a {
  color: var(--barra-dropdown-hover-font) !important;
  background: var(--barra-dropdown-hover) !important;
}

.page-header
  .page-header-menu
  .hor-menu
  .navbar-nav
  > li
  .dropdown-menu
  li.active
  > a,
.page-header
  .page-header-menu
  .hor-menu
  .navbar-nav
  > li
  .dropdown-menu
  li.active
  > a:hover,
.page-header
  .page-header-menu
  .hor-menu
  .navbar-nav
  > li
  .dropdown-menu
  li.current
  > a,
.page-header
  .page-header-menu
  .hor-menu
  .navbar-nav
  > li
  .dropdown-menu
  li.current
  > a:hover {
  color: white !important;
  background: var(--barra-menu-hover) !important;
}

.page-wrapper-middle {
  background-color: var(--background--geral) !important;
}

.md-checkbox-inline .md-checkbox {
  margin-right: 16px !important;
}

.react-datepicker {
  font-size: 0.8em;
}
.react-datepicker__header {
  padding-top: 0.8em;
}
.react-datepicker__month {
  margin: 0.4em 1em;
}
.react-datepicker__day-name,
.react-datepicker__day {
  width: 1.9em;
  line-height: 1.9em;
  margin: 0.166em;
}
.react-datepicker__current-month {
  font-size: 1.1em;
}
.react-datepicker__navigation {
  top: 1em;
  line-height: 1.7em;
  border: 0.45em solid transparent;
}
.react-datepicker__navigation--previous {
  border-right-color: #ccc;
  left: 1em;
}
.react-datepicker__navigation--next {
  border-left-color: #ccc;
  right: 1em;
}

.react-datepicker-popper {
  z-index: 2;
}

.dropup .dropdown-menu,
.navbar-fixed-bottom .dropdown .dropdown-menu {
  bottom: -340% !important;
}

.header-table-left {
  float: left;
}

.tabbable-line > .nav-tabs > li.active {
  border-bottom: 4px solid var(--minha-conta-background) !important;
}

.tabbable-line > .nav-tabs > li:hover {
  border-bottom: 4px solid var(--minha-conta-hover) !important;
}

.note.note-success {
  background-color: #eef7ea !important;
  border-color: #c9e2b3 !important;
  color: #3c763d !important;
}

.floating-button {
  position: absolute;
  bottom: 20px;
  right: 47%;
  cursor: pointer;
}

.logo-default {
  margin: 12.5px 0px 0px 0px !important;
  height: 50;
}

.floating-input {
  z-index: 0;
  position: absolute;
  left: 0px;
  top: 0px;
}
.my-form-control {

  display: block;
  width: 165%;
  height: 34px;
  padding: 6px 12px 6px 30px;
  font-size: 14px;
  line-height: 1.42857;
  margin-top: 8px;
  color: #555555;
  background-color: #fff;
  background-image: none;
  border: 1px solid #c2cad8;
  border-radius: 4px;
  -webkit-box-shadow: inset 0 1px 1px rgba(0, 0, 0, 0.075);
  box-shadow: inset 0 1px 1px rgba(0, 0, 0, 0.075);
  -webkit-transition: border-color ease-in-out 0.15s,
    box-shadow ease-in-out 0.15s;
  -o-transition: border-color ease-in-out 0.15s, box-shadow ease-in-out 0.15s;
  -webkit-transition: border-color ease-in-out 0.15s,
    -webkit-box-shadow ease-in-out 0.15s;
  transition: border-color ease-in-out 0.15s,
    -webkit-box-shadow ease-in-out 0.15s;
  transition: border-color ease-in-out 0.15s, box-shadow ease-in-out 0.15s;
  transition: border-color ease-in-out 0.15s, box-shadow ease-in-out 0.15s,
    -webkit-box-shadow ease-in-out 0.15s;
}

.daterangepicker .ranges ul {
  list-style: none;
  margin: 0;
  padding: 0;
}

.daterangepicker .ranges li {
  font-size: 13px;
  background: #f2f2f2;
  border: 1px solid #f2f2f2;
  color: #08c;
  padding: 3px 12px;
  margin-bottom: 8px;
  -webkit-border-radius: 5px;
  -moz-border-radius: 5px;
  border-radius: 5px;
  cursor: pointer;
}

.daterangepicker .ranges .range_inputs > div {
  float: left;
}

.daterangepicker.opensleft .ranges,
.daterangepicker.opensleft .calendar {
  float: left;
  margin: 4px;
}

.daterangepicker_start_input {
  position: absolute;
  top: 0;
  left: 189px;
}

.daterangepicker_end_input {
  position: absolute;
  top: 0;
  left: 388px;
}

.daterangepicker.opensleft::before {
  position: absolute;
  top: -7px;
  right: 9px;
  display: inline-block;
  border-right: 7px solid transparent;
  border-bottom: 7px solid #ccc;
  border-left: 7px solid transparent;
  border-bottom-color: rgba(0, 0, 0, 0.2);
  content: "";
}

.daterangepicker.opensleft::after {
  position: absolute;
  top: -6px;
  right: 10px;
  display: inline-block;
  border-right: 6px solid transparent;
  border-bottom: 6px solid #fff;
  border-left: 6px solid transparent;
  content: "";
}

.login .logo {
  margin: auto;
  padding: 15px;
  text-align: center;
}

.copyright {
  text-align: center;
  margin: 0 auto;
  padding: 10px;
  color: #eee;
  font-size: 13px;
}

.login .content {
  width: 360px;
  margin: 0 auto;
  margin-bottom: 0px;
  padding: 30px;
  padding-top: 20px;
  padding-bottom: 15px;
  -webkit-border-radius: 7px !important;
  -moz-border-radius: 7px !important;
  -ms-border-radius: 7px !important;
  -o-border-radius: 7px !important;
  border-radius: 7px !important;
}



.login .content .login-form,
.login .content .forget-form {
  padding: 0px;
  margin: 0px;
}

.login .content h3 {
  color: #eee;
}

.login .content .form-title {
  font-weight: 300;
  margin-bottom: 25px;
}

.login .content .form-actions .checkbox {
  margin-top: 0px;
  display: inline-block;
}

.login .content .form-actions .checkbox {
  margin-left: 25px;
  margin-bottom: 15px;
  padding-left: 0;
}

.login .content p,
.login .content label {
  color: #fff;
}

.login .content .form-actions {
  background-color: transparent;
  clear: both;
  border: 0px;
  padding: 0px 30px 25px 30px;
  margin-left: -30px;
  margin-right: -30px;
}

.backstretch {
  left: 0px;
  top: 0px;
  overflow: hidden;
  margin: 0px;
  padding: 0px;
  height: 100%;
  width: 100%;
  z-index: -999999;
  position: fixed;
}

.backstretch img {
  position: absolute;
  margin: 0px;
  padding: 0px;
  border: medium none;
  width: 100%;
  height: 100%;
  max-height: none;
  max-width: none;
  z-index: -999999;
  left: 0px;
  top:0;
}

.react-autosuggest__suggestions-container--open {
  border-radius: 3px;
  box-shadow: rgba(0, 0, 0, 0.1) 0px 2px 12px;
  background: rgba(255, 255, 255, 1) none repeat scroll 0% 0%;
  padding: 2px 0px;
  font-size: 100%;
  position: absolute;
  top: 35px;
  left: 27px;
  width: 96%;
  z-index: 999 !important;
}

.react-autosuggest__suggestion--highlighted {
  background: lightgray none repeat scroll 0% 0%;
}

.react-autosuggest__suggestion {
  border-bottom: 1px solid lightgrey;
}

.react-autosuggest__suggestions-list {
  padding: 0;
  list-style-type: none;
}

.autoComp div {
  z-index: 10;
}

.func-autocomp .react-autosuggest__suggestions-container--open {
  border-radius: 3px;
  box-shadow: rgba(0, 0, 0, 0.1) 0px 2px 12px;
  background: rgba(255, 255, 255, 1) none repeat scroll 0% 0%;
  padding: 2px 0px;
  font-size: 100%;
  position: absolute;
  top: 34px;
  left: 15px;
  width: 92%;

  z-index: 999 !important;
}

.favoritos-dialog-modal {
  width: 98% !important;
}

.select2-dropdown {
  z-index: 9999999999999999 !important;
}

.breadcrumb .fa {
  font-size: 14px !important;
  color: #ffffff !important;
} /* icone do caminho */
.breadcrumb a {
  color: #ffffff !important;
} /* icone do caminho */

a {
  color: var(--cor-empresas) !important;
} /* cores do menu em cima ( empresa ) */

a:hover,
a:active {
  color: var(--barra-menu-hover) !important;
}

.page-head .page-title > h1 {
  color: #ffffff !important;
}

.portlet.light > .portlet-title > .caption {
  color: var(--cor-letra-grafico) !important;
} /* nome do grafico */

.portlet.light > .portlet-title > .caption > i {
  color: var(--cor-icone-grafico) !important;
} /* cor do icone */
.page-header {
  background-color: var(--background--geral) !important;
}
.page-header
  .page-header-top
  .top-menu
  .navbar-nav
  > li.dropdown-user
  > .dropdown-toggle
  > .username {
  color: var(--nome) !important; /* email/nome pessoa */
}

.page-header
  .page-header-top
  .top-menu
  .navbar-nav
  > li.dropdown-dark
  .dropdown-menu {
  background: var(
    --background--geral
  ) !important; /*menu minha conta background #d8030b */
  background-color: var(--background--geral) !important;
}

.page-header
  .page-header-top
  .top-menu
  .navbar-nav
  > li.dropdown-dark
  .dropdown-menu::after {
  border-bottom-color: var(--minha-conta-background) !important;
}

.page-header
  .page-header-top
  .top-menu
  .navbar-nav
  > li.dropdown-dark
  .dropdown-menu.dropdown-menu-default
  > li
  a:hover,
.page-header
  .page-header-top
  .top-menu
  .navbar-nav
  > li.dropdown-dark
  .dropdown-menu
  .dropdown-menu-list
  > li
  a:hover {
  background: var(
    --background--geral
  ) !important; /* hover menu minha conta #AA4343 */
}

.page-header
  .page-header-top
  .top-menu
  .navbar-nav
  > li.dropdown-dark
  .dropdown-menu.dropdown-menu-default
  > li.divider,
.page-header
  .page-header-top
  .top-menu
  .navbar-nav
  > li.dropdown-dark
  .dropdown-menu.dropdown-menu-default
  > li
  a
  > i,
.page-header
  .page-header-top
  .top-menu
  .navbar-nav
  > li.dropdown-dark
  .dropdown-menu
  .dropdown-menu-list
  > li
  a
  > i,
.page-header
  .page-header-top
  .top-menu
  .navbar-nav
  > li.dropdown-dark
  .dropdown-menu.dropdown-menu-default
  > li
  a,
.page-header
  .page-header-top
  .top-menu
  .navbar-nav
  > li.dropdown-dark
  .dropdown-menu
  .dropdown-menu-list
  > li
  a {
  color: var(--cor-letra-dropdown) !important; /* email/nome pessoa */
} /* cor da letra menu minha conta */

.page-header .page-header-top .top-menu {
  margin: 0 !important;
}

.page-header
  .page-header-menu
  .hor-menu
  .navbar-nav
  > li.classic-menu-dropdown
  .dropdown-menu {
  min-width: 214px !important;
}

.pagamentoOKx {
  color: var(--cor-05) !important;
  font-weight: bold;
  padding: 10;
  background: var(--cor-02) !important;
}
.pagamentoOKx:hover {
  color: var(--cor-05) !important;
  font-weight: bold;
  padding: 10;
  background: var(--cor-02 + "33") !important;
}
.pagamentoNOKx {
  color: var(--cor-02) !important;
  padding: 10;
  background: var(--cor-05) !important;
}
.pagamentoNOKx:hover {
  color: var(--cor-02) !important;
  padding: 10;
  background: var(--cor-05 + "33") !important;
}
.pagamentoOK {
  color: #ffffff;
  font-weight: bold;
  padding: 10;
  width: 90%;
  background: var(--barra-menu) !important;
}
.pagamentoOK:hover {
  color: #ffffff;
  font-weight: bold;
  padding: 10;
  width: 90%;
  background: var(--barra-menu-hover) !important;
}
.pagamentoNOK {
  color: #000000;
  padding: 10;
  width: 90%;
  background: #cccccc !important;
}
.pagamentoNOK:hover {
  color: #000000;
  padding: 10;
  width: 90%;
  background: #aaaaaa !important;
}
.pagamentoSvgOK {
  color: #ffffff;
  fill: #ffffff;
  height: 50;
  width: 50;
}
.pagamentoSvgNOK {
  color: #000000;
  fill: #000000;
  height: 50;
  width: 50;
}
.link-button2 {
  background-color: transparent;
  border: none;
  cursor: pointer;
  display: inline;
  margin: 0;
  padding: 0;
}

.link-button2:hover,
.link-button2:focus {
  text-decoration: none;
}
.link-button {
  background-color: transparent;
  border: none;
  cursor: pointer;
  text-decoration: underline;
  display: inline;
  margin: 0;
  padding: 0;
}

.link-button:hover,
.link-button:focus {
  text-decoration: none;
}
.linhaDestinoON {
  background-color: #d4d3d3;
  border: none;
}
.linhaDestinoOff {
  background-color: #ffffff;
  border: #d4d3d3 1px solid;
}
.btn-warning-go {
  color: #fff;
  background-color: var(--barra-menu) !important;
  border-color: var(--barra-menu) !important;
}
.btn-warning-go:focus,
.btn-warning-go.focus {
  color: #fff;
  background-color: var(--barra-menu) !important;
  border-color: var(--barra-menu) !important;
}
.btn-warning-go:hover {
  color: #fff;
  background-color: var(--barra-menu) !important;
  border-color: var(--barra-menu) !important;
}
.btn-warning-go:active,
.btn-warning-go.active,
.open > .btn-warning-go.dropdown-toggle {
  color: #fff;
  background-color: var(--barra-menu) !important;
  border-color: var(--barra-menu) !important;
}
.btn-warning-go:active:hover,
.btn-warning-go:active:focus,
.btn-warning-go:active.focus,
.btn-warning-go.active:hover,
.btn-warning-go.active:focus,
.btn-warning-go.active.focus,
.open > .btn-warning-go.dropdown-toggle:hover,
.open > .btn-warning-go.dropdown-toggle:focus,
.open > .btn-warning-go.dropdown-toggle.focus {
  color: #fff;
  background-color: var(--barra-menu) !important;
  border-color: var(--barra-menu) !important;
}
.btn-warning-go:active,
.btn-warning-go.active,
.open > .btn-warning-go.dropdown-toggle {
  background-image: none;
}
.btn-warning-go.disabled:hover,
.btn-warning-go.disabled:focus,
.btn-warning-go.disabled.focus,
.btn-warning[disabled]:hover,
.btn-warning[disabled]:focus,
.btn-warning[disabled].focus,
fieldset[disabled] .btn-warning-go:hover,
fieldset[disabled] .btn-warning-go:focus,
fieldset[disabled] .btn-warning-go.focus {
  background-color: var(--barra-menu) !important;
  border-color: var(--barra-menu) !important;
}
.btn-warning .badge {
  color: var(--barra-menu) !important;
  background-color: #fff;
}

.navbar-default,
.navbar-default > li,
.navbar-nav > li > a,
.open,
.open:hover,
.open:focus,
.open > a,
.dropdown-menu > a,
.dropdown-menu > li > a,
.dropdown-menu > li,
.dropdown-menu,
.navbar-default > li:hover,
.navbar-default > li:focus,
.navbar-nav > li > a:hover,
.navbar-nav > li > a:focus,
.open > a:focus,
.open > a:hover,
.dropdown-menu > a:hover,
.dropdown-menu > a:focus,
.dropdown-menu:focus,
.dropdown-menu:hover {
  background-color: var(--barra-menu) !important;
  border-color: var(--barra-menu) !important;
  color: var(--cor-letra-menu) !important;
}

.dropdown-menu > li > a:hover,
.dropdown-menu > li > a:focus,
.dropdown-menu > li:hover,
.dropdown-menu > li:focus {
  border: 1px inset var(--cor-letra-menu) !important;
  background-color: var(--barra-menu) !important;
  color: var(--cor-letra-menu) !important;
}
.navbar-collapse {
	height: var(--nav-heade-size1) !important;
	padding: 0px !important;
  margin-left: 0px !important;
  margin-right: 0px !important;
  margin-top: 0px !important;
  margin-bottom: 0px !important;
}

.navbar-nav > li {
	height: var(--nav-heade-size1) !important;
	padding-top: var(--nav-heade-size2) !important;
	padding-bottom: var(--nav-heade-size2) !important;
  margin-left: 10px !important;
  margin-right: 0px !important;
  margin-top: 0px !important;
  margin-bottom: 0px !important;
}
.navbar-brand {
	height: var(--nav-heade-size1) !important;
	width: var(--nav-heade-size1) !important;
  	padding: 0px !important;
    margin: 0px !important;
    overflow: hidden;
  margin-left: 7px !important;
  margin-right: -7px !important;
  -webkit-border-radius: var(--round-size5) !important;
  -moz-border-radius: var(--round-size5) !important;
  -ms-border-radius: var(--round-size5) !important;
  -o-border-radius: var(--round-size5) !important;
  border-radius: var(--round-size5) !important;
  white-space: normal;
}

.navbar-default,
.navbar-default > li,
.open,
.dropdown-menu > li,
.dropdown-menu{
	padding: 0px !important;
	margin: 0px !important;
}

.container {
  padding-left: 0px !important;
  padding-right: 0px !important;
}

.row {
  padding-left: 0px !important;
  padding-right: 0px !important;
}

.rowAjust {
  margin-right: -15px !important;
}

.loginBack {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  z-index: 0;
	background-size: cover !important;
	background-repeat: no-repeat !important;
	background-image: var(--svg);
  background-position: center center !important;
}

.loginBack2 {
  clip-path: polygon(var(--polygon));
  -webkit-clip-path: polygon(var(--polygon));
}

.zControl {
  z-index: 9999999999999999 !important;
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  z-index: 0;

}

.fontColor1 {
  color: var(--cor-01) !important;
}
.fontColor2 {
  color: var(--cor-02) !important;
}
.fontColor3 {
  color: var(--cor-03) !important;
}
.fontColor4 {
  color: var(--cor-04) !important;
}
.fontColor5 {
  color: var(--cor-05) !important;
}
.fontColor6 {
  color: var(--cor-06) !important;
}
.bgColor1 {
  background-color: var(--cor-01) !important;
}
.bgColor2 {
  background-color: var(--cor-02) !important;
}
.bgColor3 {
  background-color: var(--cor-03) !important;
}
.bgColor4 {
  background-color: var(--cor-04) !important;
}
.bgColor5 {
  background-color: var(--cor-05) !important;
}
.bgColor6 {
  background-color: var(--cor-06) !important;
}
.bdColor1 {
  border-color: var(--cor-01) !important;
}
.bdColor2 {
  border-color: var(--cor-02) !important;
}
.bdColor3 {
  border-color: var(--cor-03) !important;
}
.bdColor4 {
  border-color: var(--cor-04) !important;
}
.bdColor5 {
  border-color: var(--cor-05) !important;
}
.bdColor6 {
  border-color: var(--cor-06) !important;
}
.round7 {
  -webkit-border-radius: var(--round-size7) !important;
  -moz-border-radius: var(--round-size7) !important;
  -ms-border-radius: var(--round-size7) !important;
  -o-border-radius: var(--round-size7) !important;
  border-radius: var(--round-size7) !important;
  white-space: normal;
}
.round5 {
  -webkit-border-radius: var(--round-size5) !important;
  -moz-border-radius: var(--round-size5) !important;
  -ms-border-radius: var(--round-size5) !important;
  -o-border-radius: var(--round-size5) !important;
  border-radius: var(--round-size5) !important;
  white-space: normal;
}
.round12 {
  -webkit-border-radius: var(--round-size12) !important;
  -moz-border-radius: var(--round-size12) !important;
  -ms-border-radius: var(--round-size12) !important;
  -o-border-radius: var(--round-size12) !important;
  border-radius: var(--round-size12) !important;
  white-space: normal;
}

.gradient14 {
  background-image: linear-gradient(to right, var(--cor-01), var(--cor-04));
}
.gradient32 {
  background-image: linear-gradient(to right, var(--cor-03), var(--cor-02));
}
.gradient25 {
  background-image: linear-gradient(to right, var(--cor-02), var(--cor-05));
}

.zindex999 {
  z-index: 999 !important;
}
.zindex50 {
  z-index: 50 !important;
}
.zindex10 {
  z-index: 10 !important;
}
.zindex2 {
  z-index: 2 !important;
}
.hmargin5 {
  margin-left: 5px !important;
  margin-right: 5px !important;
}

.vmargin5 {
  margin-top: 5px !important;
  margin-bottom: 5px !important;
}
.padding5 {
  padding: 5px !important;
}

.padding7 {
  padding: 7px !important;
}

.padding10 {
  padding: 10px !important;
}

.padding12 {
  padding: 12px !important;
}
.bdsColor1 {
  border: 1px solid var(--cor-01) !important;
}
.bdsColor2 {
  border: 1px solid var(--cor-02) !important;
}
.bdsColor3 {
  border: 1px solid var(--cor-03) !important;
}
.bdsColor4 {
  border: 1px solid var(--cor-04) !important;
}
.bdsColor5 {
  border: 1px solid var(--cor-05) !important;
}
.bdsColor6 {
  border: 1px solid var(--cor-06) !important;
}

.alignCenter {
  align-items: center !important;
  display: flex;
  padding-left: 10px;
}

.rc-time-picker-input {

  display: block;
  width: 200px;
  height: 34px;
  padding: 6px 12px;
  font-size: 14px;
  line-height: 1.42857;
  color: var(--cor-02);
  background-color: #fff;
  background-image: none;
  border: 1px solid #fff;
  border-radius: 4px;
}

.rc-time-picker-input:focus {
  border-color: #fff;
  outline: 0;
}
.rc-time-picker-input::-moz-placeholder {
  color: #999;
  opacity: 1; }
.rc-time-picker-input:-ms-input-placeholder {
  color: #999; }
.rc-time-picker-input::-webkit-input-placeholder {
  color: #999; }
.rc-time-picker-input::-ms-expand {
  border: 0;
  background-color: transparent; }

  .rc-time-picker-panel-narrow {
    max-width: 300px !important;
  }