/* Cubic Bezier Transition */
/***
Invoice page
***/
.invoice {
  margin: 0; }
  .invoice table {
    margin: 30px 0 30px 0; }

.invoice .invoice-logo {
  margin-bottom: 20px; }

.invoice .invoice-logo p {
  padding: 5px 0;
  font-size: 26px;
  line-height: 28px;
  text-align: right; }

.invoice .invoice-logo p span {
  display: block;
  font-size: 14px; }

.invoice .invoice-logo-space {
  margin-bottom: 15px; }

.invoice .invoice-payment strong {
  margin-right: 5px; }

.invoice .invoice-block {
  text-align: right; }

.invoice .invoice-block .amounts {
  margin-top: 20px;
  font-size: 14px; }
