.modal {
    justify-content: 'center';
    align-items: 'stretch';
    height: null;
  }
.button {
    background-color: "#f5ca1e";
    justify-content: 'space-between';
    align-items: 'center';
    padding: 20;
    border-radius: 5;
  }
.buttonText {
    padding: 0;
    color: '#000000';
    font-weight: 'bold';
    font-size: 14;
  }
.buttonText2 {
    padding: 0;
    color: '#000000';
    font-size: 14;
  }