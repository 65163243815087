.cartoes
{
  box-shadow: 0px 2px 4px rgba(0, 0, 0, 0.2);
  margin-left: 1px;
  margin-bottom: 10px;
  margin-top: 5px;
  margin-right: 8px;
  padding: 3px 10px 3px 10px;
  background-color: #FEFEFE;
}
.cartoes2
{
  box-shadow: 0px 2px 4px rgba(0, 0, 0, 0.2);
  border: 1px solid black;
  margin-left: 1px;
  margin-bottom: 1px;
  margin-top: 1px;
  margin-right: 1px;
  padding: 1px 1px 1px 1px;
  background-color: #FEFEFE;
}
.cartoes2 p
{
  line-height: 20px
}
.custom-modal {width:100% !important;}

.scrollable-list{
  flex: 1;
  min-width: 140px;
  height: 450px;
  overflow: scroll;
}
